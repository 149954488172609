import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";
import Nadpis from "../Layout/Nadpis";

const News = () => (
    <section className={"news bg-light-border"}>

        <div className="container">

            <h2 className={"text-center mb-4"}  id={"novinky"}>Novinky
                <span>Akcie & koncerty</span>
            </h2>

            <p className="text-center">
                Aktuálne ponuky, akcie a koncerty nájdete vždy tu.
            </p>

            <div className="card border-0 shadow mb-4">

                <div className="card-body">

                    <h3> Otváráme 9.1.2022!</h3>
                    <span className="badge bg-secondary">NEWS </span>
                    <p>Od 18.1.2022
                        Vás radi privítame v novo zrekonštruovanom café bare <strong>JAZZTIKOT</strong>.</p>
                    <p>Zastavte sa u nás na dobrú kávu, drink, alebo niečo malé na jedenie.</p>
                    <p><strong>
                        Nájdete nás v Starom meste - Biela 5, Bratislava </strong></p>
                </div>
            </div>
            <div className="card border-0 shadow">
                <div className="card-body">

                    <h3>Koncerty január 2022</h3>
                    <span className="badge bg-secondary">JAZZ </span>
                    <p>Vzhľadom na vládne nariadenie nie sú na mesiac január naplánované žiadne koncerty.</p>
                </div>
            </div>

        </div>
    </section>

);

export default News

