import React from "react";

const RestMenu = () => (
    <section className={"restMenu"}>

        <div className="container">

            <h2 id={"restMenu"} className={"text-center mb-4"}>Menu
                <span>Jedlo & Pitie</span>
            </h2>

<p>doplnit</p>




        </div>
    </section>

);

export default RestMenu

