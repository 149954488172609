import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";

const Welcome = () => (
    <div className={"welcome"}>
        <div className="d-flex h-100 align-items-center flex-column justify-content-center ">
            <div><h1 className={"display-1 text-secondary text-center fw-bold mb-0"}>JAZZTIKOT</h1></div>
            <div className={"mt-4"}><h2 className={"display-4 text-white  text-uppercase fw-bold"}>
                Café bar & jazz club
            </h2></div>
            <div><p className={"lead mt-3"}>

                Biela 5, Bratislava

            </p></div>


        </div>
    </div>


);

export default Welcome

