import React from "react";
import {StaticImage} from "gatsby-plugin-image";

const Photo = () => (
    <section className={"photoGallery bg-light-border"}>

        <div className="container">

            <h2 id={"fotogalerie"} className={"text-center mb-4"}>Ako to u nás vyzerá
                <span>Fotogaléria</span>
            </h2>

            <div className="row">
                <div className="col-12 col-sm-4 col-lg-4 mb-4">
                    <div className="imageCol">
                        <StaticImage imgClassName={"imgHoverEffect"} src="./images/1.jpg" alt="JazzTikot"/>
                    </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4 mb-4">
                    <div className="imageCol">
                        <StaticImage imgClassName={"imgHoverEffect"} src="./images/2.jpg" alt="JazzTikot"/>
                    </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4 mb-4">
                    <div className="imageCol">
                        <StaticImage imgClassName={"imgHoverEffect"} src="./images/3.jpg" alt="JazzTikot"/>
                    </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4 mb-4">
                    <div className="imageCol">
                        <StaticImage imgClassName={"imgHoverEffect"} src="./images/4.jpg" alt="JazzTikot"/>
                    </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4 mb-4">
                    <div className="imageCol">
                        <StaticImage imgClassName={"imgHoverEffect"} src="./images/5.jpg" alt="JazzTikot"/>
                    </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4 mb-4">
                    <div className="imageCol">
                        <StaticImage imgClassName={"imgHoverEffect"} src="./images/6.jpg" alt="JazzTikot"/>
                    </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4 mb-4">
                    <div className="imageCol">
                        <StaticImage imgClassName={"imgHoverEffect"} src="./images/7.jpg" alt="JazzTikot"/>
                    </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4 mb-4">
                    <div className="imageCol">
                        <StaticImage imgClassName={"imgHoverEffect"} src="./images/8.jpg" alt="JazzTikot"/>
                    </div>
                </div>
                

            </div>

        </div>

    </section>

);

export default Photo

