import React from "react"
import Layout from "../components/Layout/Layout"
import Welcome from "../components/Sections/Welcome";
import News from "../components/Sections/News";
import Kontakt from "../components/Sections/Kontakt";
import Photo from "../components/Sections/PhotoSection/Photo";
import RestMenu from "../components/Sections/RestMenu";

export default function Home() {
    return (
        <Layout>
            <Welcome/>
            <News/>
            <RestMenu/>
            <Photo/>
            <Kontakt/>
        </Layout>
    );
}