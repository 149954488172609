import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarker, faPhone, faClock} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";
import Nadpis from "../Layout/Nadpis";
import GoogleMap from "../Components/GoogleMap";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";

const Kontakt = () => (
    <section className={"kontakt"}>

        <div className="container">

            <h2 id={"kontakt"} className={"text-center mb-4"}>Kde nás nájdete
                <span>Kontakt</span>
            </h2>

            <div className="row">
                <div className="col-12 col-md">
                    <div className="bigIco">
                        <FontAwesomeIcon icon={faMapMarker}/>
                        <div className={"icoText"}>Biela 5, Bratislava</div>
                    </div>

                </div>
                <div className="col-12 col-md">
                    <div className="bigIco">
                        <FontAwesomeIcon icon={faClock}/>
                        <div className={"icoText"}>Út - So <br/>
                        9:00 - 22:00
                        </div>
                    </div>

                </div>
                <div className="col-12 col-md">
                    <div className="bigIco">
                        <FontAwesomeIcon icon={faPhone}/>
                        <div className={"icoText"}>+421 915 178 895</div>
                    </div>

                </div>
            </div>

            <GoogleMap/>
        </div>

    </section>

);

export default Kontakt

